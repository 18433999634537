import Vue from 'vue';
import Router from 'vue-router';
import store from './store.js';

function loadView(view) {
	return () => import(/* webpackChunkName: "view-[request]" */ `./pages/${view}.vue`)
}

Vue.use(Router);

const v3Core = loadView('_v3.3');
const v3Meta = {
	version: 3.0,
	promo: false,
	display: {
		web_footer: 'hide',
		trends: true,
	},
	// mini: true, // <<-- @Jason - this removes the header & footer (when: true)
};


// const v5Core = loadView('HomeV5');
const v5Meta = {
	version: 5.0,
	promo: false,
	mini: false,
	display: {
		web_footer: 'hide',
		body_class: [
			'clear'
		],
	},
};

const router = new Router({
	mode: 'history',

	// GO TO BOTTOM FOR SCROLL CACHE/BEHAVIOUR

	routes: [

		{
			path: '*',
			name: 'not-found',
			component: loadView('404'),
		},


		{
			path: '/dashboard',
			name: 'Dashboard',
			component: loadView('Dashboard'),
			meta: v5Meta,
		},
		{
			path: '/login',
			name: 'Login',
			component: loadView('Section'),
			meta: { promo: false },
		},
		{
			path: '/deck',
			name: 'Deck',
			component: loadView('Deck'),
			meta: { promo: false, mini: true },
		},
		// {
		// 	path: '/trends/:topics?',
		// 	redirect: {
		// 		name: 'Analyse'
		// 	}
		// },
		// {
		// 	path: '/weekly',
		// 	name: 'OneSub - Weekly',
		// 	component: loadView('weekly/Weekly'),
		// 	meta: { promo: false },
		// },

		{
			path: '/analyse/working',
			name: 'Working',
			component: loadView('Analysing'),
			meta: { promo: false },
		},
		{
			path: '/analyse/covid',
			name: 'Analysing',
			component: loadView('AnalysingCovid'),
			meta: { promo: false },
		},
		{
			path: '/analyse/retry',
			name: 'Analysing Reload',
			component: loadView('AnalysingReload'),
			meta: { promo: false },
		},
		{
			path: '/account',
			component: loadView('Account'),
			meta: { promo: false },
			children: [
				{ path: '', component: loadView('account/Communication') },
				{ path: 'signin', component: loadView('account/Details') },
				{ path: 'communication', component: loadView('account/Communication') },
				{ path: 'threads', component: loadView('account/Threads') },
				{ path: 'pro', component: loadView('account/Pro') },
				{ path: 'manage', component: loadView('account/Manage') },
				{ path: 'debug', component: loadView('account/Debug') },
			]
		},
		{
			path: '/account/stripe/done',
			name: 'Account Stripe',
			component: loadView('AccountStripe'),
			meta: { promo: false },
		},
		{
			path: '/tools/stories',
			name: 'Tools: Story Merge',
			component: loadView('ToolsStories'),
			meta: { promo: false },
		},
		
		// --------------- MY STORY / MY PAPER / ONEPAPER -----------------
		{
			path: '/share',
			name: 'Share, Create & Bookmark with OneSub',
			component: loadView('Share'),
			meta: { promo: false, mini: true },
		},
		{
			path: '/~:xname/:slug/:clip?',
			name: 'Create, Share & Bookmark Intelligently',
			component: loadView('Thread'),
			meta: { promo: false },
		},
		{
			path: '/~:xname',
			name: 'Create, Share & Bookmark intelligently with OneSub',
			component: loadView('ThreadList'),
			meta: { promo: false },
		},





		// --------------- HAND BUILT LANDERS -----------------
		{
			path: '/mission',
			name: 'Mission',
			component: loadView('landers/Mission'),
			meta: { promo: false },
		},
		{
			path: '/influencers',
			name: 'Influencers',
			component: loadView('Influencers'),
		},

		{
			path: '/threads*', // wildcarded for ad campaigns & OG variation /threads/clip-thread-share etc.
			name: 'Threads',
			component: loadView('Threads'),
			meta: { promo: false },
		},



		{
			path: '/',
			name: 'OneSub',
			component: loadView('_v6_news'),
			meta: v5Meta,
		},

		{
			path: '/topic/:topic',
			name: 'Topic',
			component: loadView('_v6_news'),
			meta: v5Meta,
		},


		// --------------- v3 - LEGACY! -----------------


		{
			path: '/:mode(story)/:slug/v3',
			name: 'Story v3',
			component: v3Core,
			meta: {
				...v3Meta,
				mode: 'story', // masks the /story hardcoding during transition
			},
		},
		{
			path: '/:mode(story)/:slug/',
			name: 'Story',
			component: v3Core,
			meta: {
				...v3Meta,
				mode: 'story', // masks the /story hardcoding during transition
			},
		},
		{
			path: '/:mode(story)/:slug/:node?/:card?',
			name: 'Article',
			component: v3Core,
			meta: {
				...v3Meta,
				mode: 'story', // masks the /story hardcoding during transition
			},
		},
		{
			path: '/section/:name',
			name: 'Section',
			component: loadView('Section'),
			meta: { promo: true },
		},

		{
			path: '/mine',
			name: 'Mine',
			component: loadView('Mine'),
			meta: v5Meta,
		},

		{
			path: '/search',
			name: 'Search',
			component: loadView('Search'),
			meta: v5Meta,
		},


		/* -----------------------------------------------------------------------
		v5 - Topics
		----------------------------------------------------------------------- */
		{
			path: '/topics',
			name: 'Topics',
			component: loadView('Topics'),
			meta: { promo: false },
		},
		// {
		// 	path: '/topic/:name',
		// 	name: 'Topic',
		// 	component: loadView('Topic'),
		// 	meta: { promo: true },
		// },

		/* -----------------------------------------------------------------------
		v5 - Open
		----------------------------------------------------------------------- */
		{
			path: '/open',
			component: loadView('Open'),
			meta: { promo: false },
			children: [
				{ path: '', name: 'OneSub - Open', component: loadView('landers/Open'), meta: { pormo: false } },
				{ path: 'trends/:topics?', name: 'Analyse', component: loadView('Analyse'), meta: { promo: false } },
				{ path: 'publishers', name: 'Publishers', component: loadView('OpenPublishers'), meta: { promo: false } },
				{ path: 'status', name: 'Status', component: loadView('OpenStatus'), meta: { promo: false } },
				{ path: 'status/clean', name: 'Status Clean', component: loadView('OpenStatus'), meta: { promo: false, mini: true } },
			]
		},

		{
			path: '/answers',
			name: 'AnswersLander',
			component: loadView('AnswersLander'),
		},
    
		{
			path: '/answers/:slug/:seo?',
			name: 'Answer',
			component: loadView('Answers'),
		},

		{
			path: '/article/:article',
			name: 'Article (Redirect)',
			component: loadView('Article'),
		},

		{
			path: '/puzzles/emoji/:slug?',
			name: 'PuzzlesEmoji',
			component: loadView('PuzzlesEmoji'),
			meta: {
				mini: true,
				display: {
					web_footer: 'hide',
				},
			},
		},


		{
			path: '/video/:slug?',
			name: 'Video Tools',
			component: loadView('Video'),
			meta: { 
				promo: false,
				mini: true,
			},
		},
	],

	scrollBehavior(to) {
		let tabHistory = store.getters.getTab(to.path);
		// console.log(`\x1b[32m[Scroll] Retrieve: '${to.path}' : `, tabHistory);

		// IGNORE STORY-NODE scrolling!!
		// console.log(`\x1b[32m[Scroll] To: '${to.path}' : `, to);
		if ((to.params.mode == 'story') && (to.params.slug) && (to.params.node)) {
			// console.log(`\x1b[32m[Scroll] Ingore Story Nodes! ... : '${to.path}' : `);
			return;
		}

		if (tabHistory?.pos) {
			let iNow =  new Date().getTime();
			if (tabHistory?.time && ((iNow - tabHistory?.time) > (60 * 5 * 1000))) {
				// console.log(`\x1b[32m[Scroll] Stale: '${to.path}' : `, (iNow - tabHistory?.time));
				return { y: 0, behavior: 'instant', };
			}
			// console.log(`\x1b[32m[Scroll] Positioning!: '${to.path}', x:${tabHistory?.pos?.y}`);
			return { y: tabHistory?.pos?.y || 0, behavior: 'instant', }
		}

		// default
		// console.log(`\x1b[32m[Scroll] NEW!: '${to.path}'`);
		return { y: 0, behavior: 'instant', };
	},
})

router.beforeEach((to, from, next) => {
	// **ALWAYS** store... because exluding '0' means never resetting.. 
	// console.log(`\x1b[32m[Scroll] Store: `, from.path, window.scrollY);
	store.commit('setTab', {
		code: from.path,
		time: new Date().getTime(),
		pos: {
			y: window.scrollY,
		},
	});

	/**
	 * tidy paths we want to treat as the same..
	 */

	// remove 'node' from story pages
	let sTo = to.path.replace(/(?<=\/story\/[a-z0-9]{16}).*/, '');
	let sFrom = from.path.replace(/(?<=\/story\/[a-z0-9]{16}).*/, '');

	// console.log(`\x1b[32m[History] Store: ${sFrom} -> ${sTo}`);

	if (sTo != sFrom) {
		store.commit('setNavHistory', {
			code: from.path,
		});
	}

	next();
});

export default router;
